
.header-wrapper {
  display: flex;
  flex-direction: row;
  padding-top: 1.5rem;
  padding-bottom: 0;
  align-items: center;

  .header {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .title {
      margin-top: .5625rem;
      margin-bottom: .1625rem;
      letter-spacing: -.02em;
      font-size: 1.625rem;
      font-weight: 500;
      line-height: 1.1;
    }
  }
}

.header-wrapper.bottom-line {
  border-bottom: 1px solid $background-accent-line-color;
  padding-bottom: 1.5rem;
}