.avatar-selector-root {
  display: flex;
  flex-direction: column;

  .avatar-selector-control {
    .avatar-selector-image {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 50%;
      width: 7rem;
      height: 7rem;
      position: relative;
      border: 2px solid #dadada;

      .avatar-selector-button {
        display: flex;
        width: 3rem;
        height: 3rem;
        position: absolute;
        background-color: #f1f5f9;
        border: 1px solid #e2e8f0;
        border-radius: 50%;
        bottom: -10%;
        right: -10%;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .avatar-selector-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    height: 100vh;
    background-color: #f9fbfd;
    width: 100%;
    top: 0;
    z-index: 1000;
    left: 0;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;

    .avatar-selector-menu-content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      justify-content: center;

      .avatar-selector-menu-item {
        display: flex;
        border-radius: 0.5rem;
        background-color: #dadada;
        cursor: pointer;
        transition: all 0.3s;
        height: 7rem;
        padding: 1rem;
        justify-content: center;
        align-items: center;

        &.selected {
          background-color: #0f172a;
          color: #fff;
        }

        .avatar-selector-menu-item-image {
          border-radius: 0.5rem;
          width: 100%;
          height: 100%;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
