.deprecated-ui {
  button {
    font-family: inherit;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 0.5rem;
    padding: 0.75rem;
    font-size: 0.95rem;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    border-color: $background-accent-line-color;
    color: #283e59;
    background-color: #fff;

    .icon {
      align-items: center;
      justify-content: center;
    }
  }

  button:not(:disabled):not(.disabled):active {
    color: #283e59;
    background-color: #e6e6e6;
    border-color: #dfdfdf;
  }

  button:focus,
  button:hover {
    background-color: #f9fbfd;
    border-color: #d2ddec;
  }

  button:hover {
    color: #283e59;
  }

  button:not(:disabled) {
    cursor: pointer;
  }

  button:focus {
    color: #283e59;
    background-color: #f9fbfd;
    border-color: #d2ddec;
    box-shadow: 0 0 0 0.15rem rgba(223, 226, 230, 0.5);
    outline: 0 !important;
  }

  button:disabled {
    opacity: 0.65;
  }

  // Rounded button
  button.rounded {
    color: inherit;
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 50%;
    flex-shrink: 0;
    border-color: transparent;
    background-color: transparent;
    mat-icon {
      width: auto;
      height: auto;
      vertical-align: top;
    }
  }

  button.rounded:hover {
    background-color: #eef2f5;
  }

  // Primary button

  button.primary {
    color: #fff;
    background-color: $primary-color;
  }

  button.primary {
    color: #fff;
    background-color: $primary-color;
  }

  button.primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: transparentize($primary-color, 0.3);
    border-color: transparentize($primary-color, 0.4);
  }

  button.primary:focus,
  button.primary:hover {
    color: #fff;
    background-color: transparentize($primary-color, 0.1);
    border-color: transparentize($primary-color, 0.3);
  }

  button.primary:focus {
    box-shadow: 0 0 0 0.15rem transparentize($primary-color, 0.8);
  }

  // Danger button

  button.danger {
    color: #fff;
    background-color: $danger-color;
  }

  button.danger {
    color: #fff;
    background-color: $danger-color;
  }

  button.danger:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: transparentize($danger-color, 0.3);
    border-color: transparentize($danger-color, 0.4);
  }

  button.danger:focus,
  button.danger:hover {
    color: #fff;
    background-color: transparentize($danger-color, 0.1);
    border-color: transparentize($danger-color, 0.3);
  }

  button.danger:focus {
    box-shadow: 0 0 0 0.15rem transparentize($danger-color, 0.8);
  }

  // Yellow button

  button.yellow {
    background: linear-gradient(270deg, #f98316 0%, #f0c93f 99.7%);
    color: #000000;
  }
}
