@import './variables.scss';
@import './breakpoints.scss';
@import './font-faces.scss';
@import './button.scss';
@import './input.scss';
@import './select.scss';
@import './header.scss';
@import './tabs.scss';
@import './card.scss';
@import './forms.scss';
@import './textarea.scss';
@import './containers.scss';
@import './progress-bar.scss';
@import './breadcrum.scss';
@import './table.scss';
@import './list.scss';

.small {
  font-size: 0.8125rem;
  font-weight: 400;
}
