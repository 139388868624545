.sh-toast {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  .sh-toast-content {
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    color: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
    z-index: 1000;
  }
}
