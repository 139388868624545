.event-host-section-root {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  font-weight: lighter;
  font-size: 0.8rem;

  .event-host-section-username {
    font-weight: bold;
  }
}
