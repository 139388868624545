.card {
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid $background-light-line-color;
  margin-bottom: 1.5rem;

  &.inactive {
    border-color: $background-accent-line-color;
    border-style: dashed;
    background-color: transparent;
    box-shadow: none;
  }

  .card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 1.5rem;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 1px solid $background-light-line-color;
  }

  .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.5rem;
  }

  .card-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    margin-bottom: 0;
    background-color: transparent;
    border-top: 1px solid $background-light-line-color;
  }

  &.raised {
    box-shadow: 0 4px 8px 0 rgba(60, 64, 67, 0.3),
      0 4px 10px 4px rgba(60, 64, 67, 0.15);
  }
}
