.event-time-window-indicator-root {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.9rem;
  padding: 0.5rem;
  justify-content: center;
  border-radius: 0.5rem;

  &.closed {
    background-color: red;
    color: white;
  }

  &.about-to-start {
    background: linear-gradient(270deg, #f98316 0%, #f0c93f 100%);
    color: white;
  }

  &.in-progress {
    background-color: green;
    color: white;
  }
}
