.date-selector-root {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .date-selector-calendar {
    justify-content: center;
    padding: 1rem;
    display: flex;

    .selected {
      font-weight: bold;
      font-size: 1.2rem;
    }
  }

  .date-selector-time-input {
    .mantine-TimeInput-input {
      font-size: 1rem;
      color: #283e59;
      font-weight: lighter;
      border-radius: 0.5rem;
      text-align: center;
    }
  }
}
