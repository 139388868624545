.event-editor-overview-root {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;

  .event-editor-overview-platforms {
    border: 1px solid #eaeaea;
    border-radius: 0.5rem;
    padding: 0.3rem 1rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }

  .event-editor-overview-save {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    .note {
      display: block;
      text-align: center;
      color: #333;
      font-size: 0.8rem;
      font-weight: 300;
      width: 100%;
    }
  }
}
