.sh-text-area {
  textarea {
    color: #283e59;
    font-size: 1rem;
  }

  textarea:focus {
    border-color: #bababa;
  }

  .mantine-TextArea-label {
    font-size: 1rem;
    color: #283e59;
    font-weight: lighter;
  }
}
