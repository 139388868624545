.main-layout-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;

  .main-layout-content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    flex: 1;
  }

  .main-layout-background-wrapper {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;

    .main-layout-background {
      display: flex;
      position: absolute;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      filter: blur(25px) brightness(0.5);
      width: 120%;
      height: 120%;
      left: -10%;
      top: -10%;
    }
  }
}
