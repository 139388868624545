$grid-gutter-width: 30px;
$container-sm: (720px + $grid-gutter-width);
$container-md: (940px + $grid-gutter-width);
$container-lg: (1140px + $grid-gutter-width);

.responsive-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @media (min-width: $screen-sm) {
    width: $container-sm;
  }
  @media (min-width: $screen-md) {
    width: $container-md;
  }
  @media (min-width: $screen-lg) {
    width: $container-lg;
  }
}
