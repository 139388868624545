.event-lounge-actions-section-root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;

  .event-lounge-actions-section-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  button {
    flex: 1;
  }
}
