.event-poster-root {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;

  .event-poster-image {
    width: 100%;
    height: 12rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #000000;
  }

  .event-poster-actions {
    position: absolute;
    top: 0;
    right: 0;
  }
}
