.event-comments-section-root {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .event-comments-section-header {
    display: flex;
    flex-direction: row;
    font-size: 0.8rem;
    font-weight: bold;
    gap: 0.5rem;
  }

  .event-comments-section-controls {
    display: flex;
    gap: 0.5rem;
    flex-direction: row;

    .sh-input {
      flex: 1;
    }
  }

  .event-comments-section-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
