$font-family: 'Inter';
$page-background-color: #f9fbfd;
$default-font-color: #020817;
$background-accent-line-color: #e3ebf6;
$background-light-line-color: #edf2f9;
$secondary-font-color: #95aac9;
$secondary-font-color-accented: #6e84a3;
$primary-color: #0f172a;
$success-color: #00d97e;
$info-color: #39afd1;
$warning-color: #f6c343;
$danger-color: #f44336;

$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;
