table.table {
  width: 100%;
  font-size: .8125rem;
  border-collapse: collapse;

  thead {
    th {
      font-size: .725rem;
      vertical-align: middle;
      background-color: #f9fbfd;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: .08em;
      color: #95aac9;
      border-top: 1px solid #edf2f9;
      border-bottom: 1px solid #edf2f9;
      white-space: nowrap;
      padding: 1rem;
      text-align: start;
    }
  }

  td {
    font-size: .825rem;
    vertical-align: middle;
    border-top: 1px solid #edf2f9;
    border-bottom: 1px solid #edf2f9;
    padding: 1rem;
    text-align: start;
  }
}

table.table.clickable-rows {

  tr {
    cursor: pointer;
    transition: background-color .5s;
    outline: none;

    button.rounded {
      background-color: transparent;
    }
  }

  tr:hover {
    background-color: #edf2f9;
  }
}