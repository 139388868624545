.loading-overlay-root {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .spinner {
    width: 3rem;
    height: 3rem;
    border: 0.5rem solid rgba(255, 255, 255, 0.3);
    border-top: 0.5rem solid white;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
