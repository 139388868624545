ul.breadcrum {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1.1;

  li {
    display: flex;
    padding: 0 .5em;

    a {
      color: $secondary-font-color;
      text-decoration: none;
      cursor: pointer;
    }

    a:hover {
      color: $secondary-font-color-accented;
    }
  }

  li::after {
    content: ' >';
    padding-left: .5em;
    color: $secondary-font-color;
  }

  li:first-child {
    padding-left: 0;
  }

  li:last-child::after {
    content: '';
    padding-left: 0;
  }
}