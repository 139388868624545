.global-error-handler-overlay {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  justify-content: center;
  align-items: center;

  .global-error-handler-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    padding: 1.25rem;
    border-radius: 0.3rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    width: 90%;

    .global-error-handler-title {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .global-error-handler-message {
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    .global-error-handler-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 1rem;
    }
  }
}
