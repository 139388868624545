.top-bar-root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1rem;

  .top-bar-title {
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
    align-self: center;
    justify-content: center;
    cursor: pointer;

    .top-bar-logo {
      height: 2rem;
      width: 5rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url('./images/logo-light.svg');
    }
  }

  .top-bar-content {
    display: flex;
    flex-direction: row;
    gap: 0.3rem;

    .top-bar-profile {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.2rem;
    }
  }

  &.light {
    .top-bar-title {
      .top-bar-logo {
        background-image: url('./images/logo-dark.svg');
      }
    }
  }
}
