@import './styles/ui-framework/index.scss';

$heading-size: 0.85rem;
$subheading-size: 0.8125rem;

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: $font-family;
  background-color: $page-background-color;
  color: $default-font-color;
}

body {
  margin: 0;
}
