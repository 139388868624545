.gaming-platforms-section {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;

  .gaming-platforms-item {
    svg {
      fill: #a3a3a3;
      width: 1.5rem;
      height: 1.5rem;
    }

    &.sm {
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    &.md {
      svg {
        width: 2rem;
        height: 2rem;
      }
    }

    &.lg {
      svg {
        width: 3rem;
        height: 3rem;
      }
    }
  }
}
