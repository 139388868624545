.event-card-root {
  background: linear-gradient(194deg, #8c7e7f 14.2%, #2a2a2a 84.61%);
  position: relative;
  min-width: 300px;
  max-width: 300px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }

  .event-poster {
    height: 250px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #000000;
  }

  .event-date {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0.3rem;
    left: 0.3rem;
    color: white;
    padding: 0.2rem 0.5rem;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(16px);
    font-size: 0.8rem;
    font-weight: bold;
  }

  .event-info-layout {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.8rem 2rem 0.8rem;

    .event-title {
      font-size: 1.1rem;
      color: white;
      font-weight: bolder;
      overflow: hidden;
      white-space: nowrap;
      padding-bottom: 0.5rem;
    }

    .event-subtitle {
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      color: #d4d4d4;
      gap: 0.4rem;
    }

    .event-game {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 0.8rem;
      color: #d4d4d4;
      gap: 0.4rem;
    }
  }
}
