.event-card-group-root {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  background-color: #f4f4f4;
  border-radius: 0.5rem;
  padding: 1rem;
  position: relative;
  justify-content: center;

  .event-card-group-content {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-grow: 0;
    flex-shrink: 0;
    width: 100%;
    overflow-x: auto;
  }

  .event-card-placeholder {
    padding: 10rem 1rem;
  }
}
