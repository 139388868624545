.deprecated-ui {
  textarea {
    font-family: inherit;
    flex: 1;
    padding: 0.75rem;
    font-size: 0.95rem;
    font-weight: 400;
    color: $default-font-color;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2ddec;
    border-radius: 0.5rem;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }

  textarea:focus {
    border-color: #bababa;
    outline: 0;
  }

  textarea:disabled,
  textarea[readonly] {
    opacity: 0.7;
  }

  textarea::placeholder {
    color: $secondary-font-color;
    opacity: 1;
  }
}
