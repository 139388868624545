.sticky-section-root {
  .sticky-section-spacer {
    display: block;
  }

  .sticky-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: fixed;
    left: 0;
    width: 100%;
    padding: 1rem;
    transition: all 0.5s ease;
    background-color: transparent;
    box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0);
    z-index: 1;

    &.to-bottom {
      bottom: 0;
      &.with-shadow {
        box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.1);
        border-top: 1px solid rgba(0, 0, 0, 0.2);
      }
    }

    &.to-top {
      top: 0;
      &.with-shadow {
        box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      }
    }

    &.acrylic {
      backdrop-filter: blur(2px);
    }
  }
}
