.sh-input {
  input {
    color: #283e59;
    font-size: 1rem;
  }

  input:focus {
    border-color: #bababa;
  }

  .mantine-TextInput-label {
    font-size: 1rem;
    color: #283e59;
    font-weight: lighter;
  }

  &.transparent {
    input {
      background: transparent;
      color: white;
    }
  }
}
