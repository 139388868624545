select {
  font-family: inherit;
  width: 100%;
  padding: 0.5rem 0.75rem;
  padding-right: 25px;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: $default-font-color;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d2ddec;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
  background: url(./images/dropdown-arrow-inactive.svg) no-repeat right #fff;
}

select:focus {
  background: url(./images/dropdown-arrow-active.svg) no-repeat right #fff;
  border-color: $primary-color;
  outline: 0;
}

select:disabled,
select[readonly] {
  opacity: 0.7;
}

select::placeholder {
  color: $secondary-font-color;
  opacity: 1;
}
