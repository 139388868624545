.gaming-platform-selector-root {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .gaming-platform-selector-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    .gaming-platfrom-selector-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding: 1rem;
      border-radius: 0.5rem;
      background-color: #dadada;
      cursor: pointer;
      transition: all 0.3s;

      &.selected {
        background-color: #0f172a;
        color: #fff;

        .gaming-platform-selector-item-icon {
          svg {
            fill: white;
          }
        }
      }

      .gaming-platform-selector-item-icon {
        width: 2rem;
        height: 2rem;

        svg {
          width: 2rem;
          height: 2rem;
          fill: #a3a3a3;
        }
      }

      .gaming-platform-selector-item-label {
        font-size: 0.8rem;
        text-align: center;
      }
    }
  }
}
