.sh-avatar {
  display: flex;
  flex-direction: column;
  align-items: center;

  .sh-avatar-image {
    border: 2px solid #dadada;
  }
  .sh-avatar-name {
    font-size: 0.7rem;
  }
}
