.home-page-root {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;

  .home-page-hero {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    padding: 0 10rem;

    @media (max-width: 1000px) {
      padding: 0;
    }

    @media (max-width: 500px) {
      flex-direction: column;

      button {
        width: 100%;
      }
    }
  }

  .home-page-tabs {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;

    @media (max-width: 330px) {
      flex-direction: column;
    }

    .home-page-tabs-entry {
      border-radius: 0.5rem;
      background-color: #f4f4f4;
      padding: 0.8rem;
      min-width: 6rem;
      display: flex;
      justify-content: center;
      font-size: 1.1rem;
      transition: all 0.3s;
      cursor: pointer;
      user-select: none;

      &.selected {
        background-color: #d4d4d4;
        color: #262626;
        font-weight: bold;
      }
    }
  }

  .home-page-stickers {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    @media (max-width: 1100px) {
      display: none;
    }

    div {
      position: absolute;
      width: 300px;
      height: 300px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .home-page-sticker1 {
      top: 0%;
      left: -25%;
      width: 250px;
      height: 250px;
      transform: rotate(-10deg);
      background-image: url('./images/sticker1.png');
    }

    .home-page-sticker2 {
      top: 30%;
      left: -30%;
      transform: rotate(30deg);
      background-image: url('./images/sticker2.png');
    }

    .home-page-sticker3 {
      top: -0%;
      right: 10%;
      transform: rotate(-30deg);
      background-image: url('./images/sticker3.png');
    }

    .home-page-sticker4 {
      bottom: -40%;
      right: -20%;
      background-image: url('./images/sticker4.png');
    }

    .home-page-sticker5 {
      top: -8%;
      right: -20%;
      background-image: url('./images/sticker5.png');
    }
  }
}
