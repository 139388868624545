.sh-button {
  border-radius: 0.5rem;

  .mantine-Button-label {
    font-weight: lighter;
    font-size: 0.95rem;
    gap: 0.3rem;
  }

  &.light {
    .mantine-Button-label {
      color: #283e59;
    }
  }

  &.uppercase {
    .mantine-Button-label {
      text-transform: uppercase;
    }
  }
}
