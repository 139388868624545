.list-entries {
  display: flex;
  flex-direction: column;

  .list-entry {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #d2ddec;
    padding: 0.5rem;
    gap: 1rem;
    cursor: pointer;

    .list-entry-image {
      width: 5rem;
      height: 5rem;
      background-size: cover;
      background-position: center;
      border-radius: 0.5rem;
      background-repeat: no-repeat;
    }

    .list-entry-title {
      display: flex;
      flex: 1;
    }

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      background-color: #f5f5f5;
    }
  }
}
