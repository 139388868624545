.event-comment-card-root {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  gap: 0.5rem;

  .event-comment-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;

    .event-comment-card-header-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;

      .event-comment-card-username {
        font-weight: bold;
        font-size: 0.7rem;
      }
    }

    .event-comment-card-header-time {
      font-size: 0.7rem;
    }
  }

  .event-comment-card-message {
    font-size: 0.85rem;
  }
}
