ul.mer-tabs {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  border-bottom: 1px solid $background-accent-line-color;
  margin: 0;
  padding: 0;
  margin-bottom: 1em;

  li {
    user-select: none;
    margin: 0 .75rem;
    color: $secondary-font-color;
    padding-top: 1.0rem;
    padding-bottom: 1.0rem;
    border: 1px solid transparent;
    cursor: pointer;
    transition: border-color .15s ease-in-out,
      color .15s ease-in-out;
  }

  li:first-child {
    margin-left: 0;
  }

  li:last-child {
    margin-right: 0;
  }

  li.active {
    color: $default-font-color;
    border-color: transparent transparent $primary-color;
    cursor: default;
  }

  li:not(.active):hover {
    color: $secondary-font-color-accented;
  }
}

ul.mer-tabs.vertical {
  flex-direction: column;
  border-bottom: 0;
  margin-bottom: 0;

  li {
    margin: 0;
    padding-left: 1em;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border: 2px solid transparent;
  }

  li.active {
    color: $default-font-color;
    border-color: transparent transparent transparent $primary-color;
  }
}