.sh-divider {
  width: 100%;
  height: 1px;

  &.light {
    background-color: #f3f3f3;
  }

  &.dark {
    background-color: #020817;
  }
}
