@import '../../../../styles/ui-framework/variables.scss';

.register-page-root {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;

  .register-page-error-message {
    display: flex;
    justify-content: center;
    background-color: $danger-color;
    color: white;
    padding: 0.5rem;
    font-size: 0.9rem;
    font-weight: lighter;
  }

  .register-page-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1rem;

    .register-page-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
