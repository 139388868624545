.event-calendar-section-root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .event-calendar-section-content {
    display: flex;
    flex-direction: column;

    .event-calendar-section-day {
      font-size: 1.2rem;
      font-weight: 600;
      padding-bottom: 0.3rem;
    }

    .event-calendar-section-time {
      font-size: 0.85rem;
      font-weight: bold;
    }

    .event-calendar-section-timezone {
      font-size: 0.8rem;
      font-weight: lighter;
    }

    .event-calendar-section-action {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }
  }
}
