.game-selector-root {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .game-selector-popular,
  .game-selector-selected {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .list-entries {
      gap: 0.2rem;

      .list-entry {
        background-color: #fff;

        &:hover {
          background-color: #f5f5f5;
        }
      }
    }
  }

  .game-selector-selected {
    .list-entry {
      background-color: #fff;
      cursor: default;

      &:hover {
        background-color: #fff !important;
      }
    }
  }

  input {
    font-family: inherit;
    padding: 0.75rem;
    font-size: 0.95rem;
    font-weight: 400;
    color: #020817;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2ddec;
    border-radius: 0.5rem;
    transition: border-color 0.15s ease-in-out;
    flex: 1;
  }

  input:focus {
    border-color: #bababa;
    outline: 0;
  }

  input:disabled,
  input[readonly] {
    opacity: 0.7;
  }

  input::placeholder {
    color: #95aac9;
    opacity: 1;
  }

  .left-icon-input {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon-wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      order: 1;

      .icon {
        position: absolute;
        left: 0.7em;
        color: #95aac9;
      }
    }

    input {
      order: 2;
      padding-left: 3.3em;
    }
  }

  .dropdown-input {
    border: 1px solid #d2ddec;
    border-radius: 0.5rem;
    background-color: #fff;

    input {
      border: 0;
      flex: 1;
    }

    &:focus-within {
      border-color: #bababa;
    }

    .dropdown-content {
      display: block;
      max-height: 35rem;
      overflow-y: auto;
      position: relative;
      border-top: 1px solid #d2ddec;
    }

    &.auto-open {
      &:focus-within {
        border-color: #bababa;

        .dropdown-content {
          display: block;
        }
      }

      .dropdown-content {
        display: none;
      }
    }
  }
}
