.sh-label {
  &.sm {
    font-size: 0.7rem;
    font-weight: lighter;
  }

  &.md {
    font-size: 1rem;
  }

  &.lg {
    font-size: 1.5rem;
    font-weight: bold;
  }

  &.centered {
    text-align: center;
  }

  &.light {
    color: #f3f3f3;

    &.secondary {
      color: #b3b3b3;
    }
  }

  &.dark {
    color: #020817;

    &.secondary {
      color: #95aac9;
    }
  }
}
