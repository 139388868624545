.event-game-section-root {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .event-game-section-poster {
    width: 5rem;
    height: 7rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #000000;
    flex-shrink: 0;
  }

  .event-game-section-content {
    display: flex;
    flex-direction: column;

    .event-game-section-title {
      font-size: 0.85rem;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}
