.event-attendees-section-root {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .event-attendees-section-count {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.8rem;
    font-weight: bold;
    gap: 0.5rem;
  }

  .event-attendees-section-avatars {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
}
