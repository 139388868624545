@import '../../../../styles/ui-framework/variables.scss';

.login-page-root {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;

  .login-page-error-message {
    display: flex;
    justify-content: center;
    background-color: $danger-color;
    color: white;
    padding: 0.5rem;
    font-size: 0.9rem;
    font-weight: lighter;
  }

  #login-page-recaptcha {
    position: fixed;
    width: 0px;
    height: 0px;
    top: 0;
    left: 0;
  }

  .login-page-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    gap: 1rem;

    .login-page-input-group {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
    }
  }
}

.grecaptcha-badge {
  z-index: 999;
}
